/* @import "typeface-optimo-plain.css";
/* @import "typeface-roboto.css"; */
@import "typeface-roboto-mono.css";
@import "vars.css";
@import "vars-extra.css";
@import "base.css";
@import "body.css";
@import "nav.css";
@import "main.css";
@import "main-cb.css";
@import "toolbar.css";
@import "toolbar-extra.css";
@import "breadcrumbs.css";
@import "page-versions.css";
@import "toc.css";
@import "doc.css";
@import "pagination.css";
@import "header.css";
/* @import "header-cb.css"; */
@import "footer.css";
/* @import "footer-cb.css"; */
@import "highlight.css";
@import "print.css";
@import "bootstrap-badges.css";
@import "bootstrap-navtabs.css";
/* @import "tippy.css"; */
/* @import "tooltip.css"; */
@import "home.css";
@import "site-extra.css";
@import "snippet.css";
@import "@asciidoctor/tabs";

@font-face {
  font-family: "Optimo-Plain";
  font-style: normal;
  font-weight: 400;
  src:
    local("Optimo Thin"),
    local("Optimo-Thin"),
    url(../font/Plain-Thin.woff) format("woff");
}

@font-face {
  font-family: "Optimo-Plain";
  font-style: normal;
  font-weight: 500;
  src:
    local("Optimo Medium"),
    local("Optimo-Medium"),
    url(../font/Plain-Medium.woff) format("woff");
}

@font-face {
  font-family: "Optimo-Plain";
  font-style: italic;
  font-weight: 400;
  src:
    local("Optimo Italic"),
    local("Optimo-Italic"),
    url(../font/Plain-RegularIta.woff) format("woff");
}

@font-face {
  font-family: "Optimo-Plain";
  font-style: italic;
  font-weight: 500;
  src:
    local("Optimo-Medium-Italic"),
    local("Optimo-Medium-Italic"),
    url(../font/Plain-MediumIta.woff) format("woff");
}

@font-face {
  font-family: "Optimo-Plain";
  font-style: normal;
  font-weight: 600;
  src:
    local("Optimo-Bold"),
    local("Optimo-Bold"),
    url(../font/Plain-Bold.woff) format("woff");
}
