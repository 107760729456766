html.is-clipped--navbar {
  overflow-y: hidden;
}

body {
  padding-top: var(--navbar-height);
}

body.snippet {
  padding-top: 0.5rem;
  margin-top: -5px;
}

.navbar {
  /* background: var(--thoughtspot-blue); */
  background: linear-gradient(89deg, rgba(68, 68, 68, 1) 0%, rgba(78, 85, 253, 1) 15%, rgba(78, 85, 253, 1) 50%, rgba(71, 74, 130, 1) 85%, rgba(68, 68, 68, 1) 100%);
  color: var(--navbar-font-color);
  font-size: calc(16 / var(--rem-base) * 1rem);
  height: var(--navbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-navbar);
}

/* added from centered default ui */
.navbar .container {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}
/* end - added from centered default ui */

.navbar a {
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.navbar-brand {
  display: flex;
  padding-left: 1rem;
  position: relative; /* changed to align search box to the right */
  z-index: var(--z-index-navbar);
}

.navbar-brand .navbar-item:first-child,
.navbar-brand .navbar-item:first-child a {
  align-self: center;
  padding: 0;
  color: var(--navbar-font-color);
  font-size: 1rem;
  /* font-size: calc(22 / var(--rem-base) * 1rem); */
  flex-grow: 100; /* added to keep search box aligned right */
}

.navbar-brand .separator {
  padding: 0 0.375rem;
}

.navbar-burger {
  background: none;
  border: none;
  outline: none;
  line-height: 1;
  position: relative;
  width: 3rem;
  margin-left: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.navbar-burger span {
  background-color: var(--navbar-font-color);
  height: 2px;
  width: 1rem;
}

.navbar-burger:not(.is-active) span {
  transition: transform ease-out 0.25s, opacity 0s 0.25s, margin-top ease-out 0.25s 0.25s;
}

.navbar-burger span + span {
  margin-top: 0.25rem;
}

.navbar-burger.is-active span + span {
  margin-top: -2px;
}

.navbar-burger.is-active span:nth-child(1) {
  transform: rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: rotate(-45deg);
}

.navbar-item,
.navbar-link {
  color: var(--navbar-menu-font-color);
  display: block;
  line-height: var(--doc-line-height);
  padding: 0.5rem 1rem;
}

.navbar-item.has-dropdown {
  padding: 0;
  margin-top: 7px; /* changed for centered ui */
  color: white;
}

.navbar-item .icon {
  width: 1.25rem;
  height: 1.25rem;
  display: block;
}

.navbar-item .icon img,
.navbar-item .icon svg {
  fill: currentColor;
  width: inherit;
  height: inherit;
}

.navbar-link {
  padding-right: 2.5em;
}

.navbar-item.has-dropdown .navbar-link {
  padding-right: 2.5em;
  margin-top: -10px;
  color: inherit;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #444;
}

.navbar-divider {
  background-color: var(--navbar-menu-border-color);
  border: none;
  height: 1px;
  margin: 0.25rem 0;
}

.navbar .button {
  display: inline-flex;
  align-items: center;
  background: var(--navbar-button-background);
  border: 1px solid var(--navbar-button-border-color);
  border-radius: 0.15rem;
  height: 1.75rem;
  color: var(--navbar-button-font-color);
  padding: 0 0.75em;
  white-space: nowrap;
}

@media screen and (max-width: 1023.5px) {
  .navbar-brand {
    height: inherit;
    width: 100%; /* added from centered default ui */
  }
}

@media screen and (min-width: 1024px) {
  .navbar-brand {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 40; /* changed to align search box to right */
  }
}

@media screen and (max-width: 1023.5px) {
  .navbar-end .navbar-item {
    margin-left: 20px; /* added from centered default ui */
  }
}

@media screen and (max-width: 1023.5px) {
  .navbar-item.has-dropdown .navbar-link {
    padding-right: 2.5em;
    margin-top: -10px;
    color: #444;
  }
}

@media screen and (max-width: 1023.5px) {
  .navbar-menu {
    max-height: calc(100vh - 3.5rem);
    background: #fff;
    -webkit-box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
    /* box-shadow: 0 8px 16px rgb(10 10 10 / 10%); */
    position: fixed;
    top: 10px;
    margin-top: 50px;
    margin-left: -20px;
    padding: 0.5rem 0;
    width: 100%;
    z-index: var(--z-index-navbar-menu);
    /* background: var(--navbar-menu-background); */
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    /* max-height: var(--body-min-height); */
    overflow-y: initial;
  }

  .navbar-brand .navbar-item {
    align-items: center;
    display: flex;
  }

  /*  .navbar-menu {
    background: var(--navbar-menu-background);
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    max-height: var(--body-min-height);
    overflow-y: auto;
    padding: 0.5rem 0;
    z-index: var(--z-index-navbar-menu);
  } */

  .navbar-menu:not(.is-active) {
    display: none;
  }

  .navbar-menu a.navbar-item:hover,
  .navbar-menu .navbar-link:hover {
    background: var(--navbar-menu_hover-background);
    color: #444;
  }
  /* new nav bar stuff - start */
  .navbar-end .navbar-item.search {
    height: 3rem;
    display: flex;
  }

  #search .query {
    height: 100%;
    /* new nav bar stuff - end */
  }
}

@media screen and (min-width: 1024px) {
  .navbar-burger {
    display: none;
  }

  .navbar,
  .navbar-menu,
  .navbar-end {
    display: flex;
  }

  .navbar-menu {
    flex: auto;
    padding-top: 13px;  /* changed for centered ui */
  }

  .navbar-end {
    margin-left: auto;
  }

  .navbar-item,
  .navbar-link {
    display: flex;
    position: relative;
    flex: none;
  }

  .navbar-item:not(.has-dropdown),
  .navbar-link {
    align-items: center;
  }

  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar-link::after {
    border-width: 0 0 1px 1px;
    border-style: solid;
    content: "";
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
  }

  .navbar-link2::after {
    border-width: 0 0 1px 1px;
    border-style: solid;
    /* content: ""; */
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
  }

  .navbar-end > .navbar-item,
  .navbar-end .navbar-link {
    color: var(--navbar-font-color);
  }

  .navbar-end > a.navbar-item:hover,
  .navbar-end .navbar-link:hover,
  .navbar-end .navbar-item.has-dropdown:hover .navbar-link {
    /* background: var(--thoughtspot-blue); */
    color: var(--navbar-font-color);
  }

  .navbar-end .navbar-link::after {
    border-color: currentColor;
  }

  .navbar-dropdown {
    background: #fff;
    border: 1px solid var(--navbar-menu-border-color);
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
    display: none;
    top: 99%;
    left: 0;
    min-width: 100%;
    position: absolute;
  }

  .navbar-dropdown .navbar-item {
    padding: 0.5rem 3rem 0.5rem 1rem;
    white-space: nowrap;
    color: #444;
  }

  .navbar-dropdown .navbar-item:last-child {
    border-radius: inherit;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  /* added from centered default ui - begin */
  .navbar-brand,
  .navbar-item + .navbar-item {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    /*  justify-content: flex-end; */
  }
  /* added from centered default ui - end */

  .navbar-dropdown a.navbar-item:hover {
    background: #f5f5f5;
  }
}

/* ss360 css - start */

.ss360-custom-search .ss360-custom-search__searchbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-position: 4px 10px;
  /* background-repeat: no-repeat;
  /* background-size: 24px 24px; */
  background: #fff url(https://d33wubrfki0l68.cloudfront.net/18fc58b185ba9ad7f7131fa6810a67e7fd8e78a7/03f57/_/img/search.svg) !important;
  background-size: 1rem !important;
  background-position: right 0.8rem center !important;
  background-repeat: no-repeat !important;
  border: 1px solid #dedede;
  border-radius: 4px !important;
  /* box-shadow: 2px 2px 4px rgb(0 0 0 / 12%); */
  box-sizing: border-box;
  font-size: inherit !important;
  height: 33px !important;
  /* margin: 0; */
  margin-bottom: 12px !important;
  /* background: #fff url(https://d33wubrfki0l68.cloudfront.net/18fc58b…/03f57/_/img/search.svg) !important; */
  max-width: 420px;
  padding: 0 8px;
  width: 100%;
}

.ss360-custom-search .ss360-custom-search__searchbutton {
  align-items: center;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  cursor: pointer;
  /* display: flex; */
  font-size: 1em;
  height: 33px !important;
  justify-content: center;
  margin-top: 0;
  padding: 0 16px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  display: none !important;
}

.ss360-suggests__image-wrap {
  display: none !important;
  line-height: 0;
  max-width: 33%;
  min-height: 100%;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 250px;
}

.ss360-layer--overlay {
  background-color: #fefefe;
  box-sizing: content-box;
  height: 80%;
  left: calc(10% - 28px);
  max-width: 800px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 32px 24px 32px 32px;
  position: fixed;
  top: calc(10% - 30px);
  width: 80%;
  border-radius: 4px !important;
  z-index: 99999999999;
}

#ss360-layer .ss360-ac-c {
  color: #000 !important;
}

#ss360-layer .ss360-ac-c--hover:focus,
#ss360-layer .ss360-ac-c--hover:hover {
  color: #fff !important;
}

/* search results window button styles */
#ss360-layer .ss360-ac-bg {
  background-color: #4e55fd !important;
  border-radius: 4px !important;
}
/* search results window button styles */
#ss360-layer .ss360-ac-bg--focus:focus,
#ss360-layer .ss360-ac-bg--hover:focus,
#ss360-layer .ss360-ac-bg--hover:hover {
  background-color: #4e55fd !important;
  color: #fefefe;
  border-radius: 4px !important;
}
/* more results button border */
#ss360-layer .ss360-ac-b,
#ss360-layer .ss360-ac-b--focus:focus,
#ss360-layer .ss360-ac-b--hover:focus,
#ss360-layer .ss360-ac-b--hover:hover {
  border-color: #4e55fd !important;
}
/* search suggestions text styles */
.home .unibox__search-content {
  display: block;
  margin-bottom: 4px;
  text-align: left;
  color: #444 !important;
  font-size: 14px !important;
}
/* search suggestions image styles */
.unibox__img-container {
  align-items: center;
  box-sizing: border-box;
  /* display: flex; */
  justify-content: flex-start;
  min-width: 68px;
  padding: 4px 0 4px 8px;
  display: none !important;
}
/* search suggestions width styles */
.unibox__content-container {
  box-sizing: border-box;
  max-width: calc(100% - 5px) !important;
  padding: 12px 8px 16px 16px;
  width: 100%;
}
/* search suggestions text color */
.unibox__search-content--link {
  color: #4a4f62 !important;
  overflow: hidden;
  text-decoration: none;
  font-size: 14px !important;
}
/* search suggestions heading text style */
.unibox__suggest-heading {
  color: #4a4f62;
  font-size: 13px !important;
  margin: 8px;
  padding: 0;
  text-align: left;
}

/* width of search box */
.ss360-custom-search {
  justify-content: center;
  max-width: 100%;
  padding: 8px 0;
  position: relative;
  width: 250px !important;
}
/* remove outline around search input field */
.ss360-n-input {
  background: #fefefe;
  background-image: none;
  border: 0;
  border-radius: 0;
  outline: none !important;
}
/* search suggestion drop-down styling */
.unibox {
  background-color: #fefefe;
  /* box-shadow: 1px 1px 6px rgb(0 0 0 / 12%); */
  -webkit-box-shadow: 0 28px 45px rgb(0 0 0 / 20%);
  color: #4a4f62;
  display: none;
  overflow: hidden;
  position: absolute;
  z-index: 1500;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

/* ss360 css - end */
