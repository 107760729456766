.doc-help {
  color: var(--doc-font-color);
  font-size: var(--doc-font-size);
  hyphens: auto;
  line-height: var(--doc-line-height);
  margin: var(--doc-margin);
  margin-top: 50px;
  max-width: var(--doc-max-width);
  padding: 0 1rem 4rem;
}

.doc {
  color: var(--doc-font-color);
  font-size: var(--doc-font-size);
  hyphens: auto;
  line-height: var(--doc-line-height);
  margin: var(--doc-margin);
  max-width: var(--doc-max-width);
  padding: 0 1rem 4rem;
}

.doc-home {
  color: var(--doc-font-color);
  font-size: var(--doc-font-size);
  hyphens: auto;
  line-height: var(--doc-line-height);
  margin: var(--doc-margin);
  max-width: 92%;
  padding: 0 1rem 4rem;
}

.doc-snippet {
  color: var(--doc-font-color);
  font-size: var(--doc-font-size);
  font-family: Optimo-Plain, sans-serif;
  hyphens: auto;
  line-height: 1.6;
  margin: var(--doc-margin);
  max-width: 100%;
  /* padding: 0 1rem 4rem; */
}

/* landing page box button styles - start */

.columns .box-button {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.2);
  margin: 0 6px 30px;
  position: relative;
  padding: 20px 20px 20px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.box-button:hover { background-color: #f3a22f; }
.box-button:active { transform: translateY(4px); }

/* landing page box button styles - start */

/*
@media only screen and (min-width: 800px)
  .doc-home .columns .box: first-child {
  margin-left: 0;
}
*/
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  margin-top: 1px;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-beta {
  background-color: #a35dff;
  color: #f5f5f5;
  margin-left: 1px;
  position: relative;
  top: 0;
  vertical-align: middle;
  padding-top: 5px;
}

.label-new {
  background-color: #00aeef;
  color: #f0f8ff;
  margin-left: 1px;
  position: relative;
  top: 0;
  vertical-align: middle;
  padding-top: 5px;
}

.label-beta-cloud {
  /*  background-color: #66CFEB; */
  background: rgb(0, 151, 205);
  background: linear-gradient(0deg, rgba(0, 151, 205, 1) 0%, rgba(102, 207, 235, 1) 100%);
  color: #ebebeb;
  margin-left: 1px;
  position: relative;
  top: 0;
  padding-top: 5px;
}

.label-dep {
  background-color: #fff;
  border: 1px solid #909090;
  color: #909090;
  margin-left: 1px;
  position: relative;
  top: 0;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  margin-top: 3px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #777;
  border-radius: 10px;
}

.badge-betatoc {
  display: inline-block;
  color: #fff;
  font-size: 11px;
  position: relative;
  /* font-weight: 800; */
  /* vertical-align: top; */
  /* margin-left: 7px; */
  padding-bottom: 3px;
  padding-top: 4.5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -4px;
  top: -1px;
  border-radius: 6px;
  border: none;
  background-color: #06bf7f;
  font-style: normal;
}

.badge-early-accesstoc {
  display: inline-block;
  color: #fff;
  font-size: 11px;
  position: relative;
  /* font-weight: 800; */
  /* vertical-align: top; */
  /* margin-left: 7px; */
  padding-bottom: 3px;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -4px;
  top: -0.007rem;
  border-radius: 6px;
  border: none;
  background-color: #8c62f5;
  font-style: normal;
}

.badge-update {
  display: inline-block;
  background-color: #a35dff;
  color: #fff;
  font-size: 9px;
  /* margin-left: 1px; */
  position: relative;
  top: 0;
  border-radius: 5px;
  /* font-weight: 800; */
  /* vertical-align: top; */
  padding-top: 4px;
  margin-top: 1px;
  /*   display: inline-block; */
  min-width: 10px;
  padding-right: 7px;
  padding-left: 7px;
  padding-bottom: 3px;
  /* font-size: 12px; */
  /* font-weight: 700; */
  line-height: 1;
  /* color: #ebebeb; */
  text-align: center;
  white-space: nowrap;
  /* vertical-align: middle; */
  /* background-color: #373BB1; */
  /* top: -1px; */
}

.badge-update-note {
  display: inline-block;
  background-color: #a35dff;
  color: #fff;
  font-size: 9px;
  /* margin-left: 1px; */
  position: relative;
  top: 0;
  border-radius: 5px;
  /* font-weight: 800; */
  /* vertical-align: top; */
  padding-top: 4px;
}

/*
  top: -1px; */

.badge-new {
  display: inline-block;
  background-color: #00aeef;
  color: #f0f8ff;
  font-size: 9px;
  margin-left: 0.5px;
  position: relative;
  top: 0;
  border-radius: 5px;
  font-weight: 800;
  vertical-align: top;
  padding-top: 4px;
  /*   display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #ebebeb;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #373BB1;
  border-radius: 5px;
  position: relative;
  top: -1px; */
}

/* .badge-update-note {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  position: relative;
  border-radius: 6px;
  padding: 4.5px 5px 3px;
  background-color: #06bf7f;
  top: -1px;
} */

.doc .badge-dep {
  display: inline-block;
  background-color: #ff595a;
  color: #fff;
  font-size: 9px;
  margin-left: 1px;
  position: relative;
  top: 2px;
  border-radius: 9px;
  font-weight: 800;
  vertical-align: top;
  padding-top: 4px;
  /*   display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #ebebeb;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #373BB1;
  border-radius: 5px;
  position: relative;
  top: -1px; */
}

.badge-deprecated {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  position: relative;
  /* font-weight: 800; */
  /* vertical-align: top; */
  /* margin-left: 7px; */
  padding-bottom: 3px;
  padding-top: 4.5px;
  padding-left: 5px;
  padding-right: 5px;
  top: -1px;
  border-radius: 6px;
  background-color: #909090;
  font-style: normal;
}

.badge-pdf {
  display: inline-block;
  background-color: #f5f5f5;
  color: #393939;
  border: 1px solid #ff2116;
  font-size: 9px;
  margin-left: 1px;
  position: relative;
  top: -2.4px;
  border-radius: 5px;
  font-weight: 800;
  vertical-align: top;
  padding-top: 4.5px;
  /*   display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #ebebeb;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #373BB1;
  border-radius: 5px;
  position: relative;
  top: -1px; */
}

@media screen and (min-width: 1024px) {
  .doc {
    flex: auto;
    font-size: var(--doc-font-size--desktop);
    margin: var(--doc-margin--desktop);
    max-width: var(--doc-max-width--desktop);
    min-width: 0;
  }
}

@media screen and (min-width: 1024px) {
  .doc {
    flex: auto;
    font-size: var(--doc-font-size--desktop);
    margin: var(--doc-margin--desktop);
    max-width: 100%;
    min-width: 0;
  }
}

@media screen and (min-width: 1024px) {
  .doc-home {
    flex: auto;
    font-size: var(--doc-font-size--desktop);
    margin: var(--doc-margin--desktop);
    max-width: 92%;
    min-width: 0;
  }
}

.doc h1,
.doc h2,
.doc h3,
.doc h4,
.doc h5,
.doc h6 {
  color: #444;
  font-weight: 500;
  /* font-size: 28px; */
  font-family: var(--body-font-family);
  hyphens: none;
  line-height: 1.3;
  /* margin: 1rem 0 0; */
}

.doc-home h1,
.doc-home h2,
.doc-home h3,
.doc-home h4,
.doc-home h5,
.doc-home h6 {
  color: #444;
  font-weight: 500;
  font-family: var(--body-font-family);
  hyphens: none;
  line-height: 1.3;
  margin: 1rem 0 0;
}

.doc > h1.page:first-child {
  /* font-size: calc(36 / var(--rem-base) * 1rem); */
  margin: 1.5rem 0;
  /* font-size: 28px; */ /* orig */
  font-size: 28px;
  font-weight: 600;
}

/* changed for optimo typeface - end */

/* changed for optimo typeface - start */
.doc-home > h1.page:first-child {
  /* font-size: calc(36 / var(--rem-base) * 1rem); */
  margin: 1.5rem 0;
  font-size: 28px;
  font-weight: 600;
  margin-left: 10px;
  text-align: left;
}

/* changed for optimo typeface - end */

/* changed for optimo typeface - start */
/* .doc-home > h1.page:first-child {
  /* font-size: calc(36 / var(--rem-base) * 1rem);
  margin: 1.5rem 0;
  font-size: 28px;
  font-weight: 600;
  margin-left: 10px;
  text-align: left;
} */

/* changed for optimo typeface - end */

@media screen and (min-width: 769px) {
  .doc > h1.page:first-child {
    margin-top: 2.5rem;
  }
}

@media screen and (min-width: 769px) {
  .doc-home > h1.page:first-child {
    margin-top: 2.5rem;
    margin-left: 10px;
    text-align: left;
  }
}

h1.page + aside.toc.embedded {
  margin-top: -0.5rem;
}

#preamble + .sect1,
.doc .sect1 + .sect1 {
  margin-top: 2rem;
}

.doc h1.sect0 {
  background: var(--abstract-background);
  font-size: 1.8em;
  margin: 1.5rem -1rem 0;
  padding: 0.5rem 1rem;
}

.doc h2:not(.discrete) {
  border-bottom: 1px solid var(--section-divider-color);
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.4rem 1rem 0.1rem;
}

.doc h3:not(.discrete) {
  font-weight: var(--alt-heading-font-weight);
}

.doc h1 .anchor,
.doc h2 .anchor,
.doc h3 .anchor,
.doc h4 .anchor,
.doc h5 .anchor,
.doc h6 .anchor {
  position: absolute;
  text-decoration: none;
  width: 1.75ex;
  margin-left: -1.5ex;
  visibility: hidden;
  font-size: 0.8em;
  font-weight: normal;
  padding-top: 0.05em;
}

.doc h1 .anchor::before,
.doc h2 .anchor::before,
.doc h3 .anchor::before,
.doc h4 .anchor::before,
.doc h5 .anchor::before,
.doc h6 .anchor::before {
  content: "\00a7";
}

.doc h1:hover .anchor,
.doc h2:hover .anchor,
.doc h3:hover .anchor,
.doc h4:hover .anchor,
.doc h5:hover .anchor,
.doc h6:hover .anchor {
  visibility: visible;
}

.doc p,
.doc dl {
  margin: 0;
  font-size: 14px; /* help */
}

.doc a {
  color: var(--link-font-color);
}

.doc a:hover {
  color: var(--link_hover-font-color);
}

.doc a.bare {
  hyphens: none;
}

.doc a.unresolved {
  color: var(--link_unresolved-font-color);
}

.doc i.fa {
  hyphens: none;
  font-style: normal;
  padding-left: 5px; /* for new all-in-one admonition */
}

.doc p code,
.doc thead code {
  color: var(--code-font-color);
  background: var(--code-background);
  border-radius: 0.25em;
  font-size: 0.95em;
  padding: 0.125em 0.25em;
}

.doc code,
.doc pre {
  hyphens: none;
}

.doc pre {
  /* font-size: calc(16 / var(--rem-base) * 1rem); */
  font-size: 0.7777rem;
  line-height: 1.5;
  margin: 0;
}

.doc blockquote {
  margin: 0;
}

.doc .paragraph.lead > p {
  font-size: calc(18 / var(--rem-base) * 1rem);
}

.doc .right {
  float: right;
}

.doc .left {
  float: left;
}

.doc .stretch {
  width: 100%;
}

.doc .underline {
  text-decoration: underline;
}

.doc .line-through {
  text-decoration: line-through;
}

.doc .paragraph,
.doc .dlist,
.doc .hdlist,
.doc .olist,
.doc .ulist,
.doc .exampleblock,
.doc .imageblock,
.doc .listingblock,
.doc .literalblock,
.doc .sidebarblock,
.doc .verseblock,
.doc .quoteblock,
.doc .partintro,
.doc details,
.doc hr {
  margin: 1rem 0 0;
}

.doc table.tableblock {
  font-size: calc(15 / var(--rem-base) * 1rem);
  margin: 1.5rem 0 0;
}

.doc table.tableblock + * {
  margin-top: 1.5rem;
}

.doc td.tableblock > .content > :first-child {
  margin-top: 0;
}

.doc table.tableblock th,
.doc table.tableblock td {
  padding: 0.5rem;
}

.doc table.tableblock thead th {
  border-bottom: 2.5px solid var(--table-border-color) !important;
  font-size: 16px;
}

.doc table.tableblock td,
.doc table.tableblock > :not(thead) th {
  border-top: 1px solid var(--table-border-color);
  border-bottom: 1px solid var(--table-border-color);
}

.doc table.stripes-all > tbody > tr,
.doc table.stripes-odd > tbody > tr:nth-of-type(odd),
.doc table.stripes-even > tbody > tr:nth-of-type(even),
.doc table.stripes-hover > tbody > tr:hover {
  background: var(--table-stripe-background);
}

.doc table.tableblock > tfoot {
  background: var(--table-footer-background);
}

.doc .halign-left {
  text-align: left;
}

.doc .halign-right {
  text-align: right;
}

.doc .halign-center {
  text-align: center;
}

.doc .valign-top {
  vertical-align: top;
}

.doc .valign-bottom {
  vertical-align: bottom;
}

.doc .valign-middle {
  vertical-align: middle;
}
/* old admonition blocks - start
.doc .admonitionblock {
  margin: 1.4rem 0 0;
}

.doc .admonitionblock p,
.doc .admonitionblock td.content {
  font-size: calc(16 / var(--rem-base) * 1rem);
}

.doc .admonitionblock td.content > :not(.title):first-child,
.doc .admonitionblock td.content > .title + * {
  margin-top: 0;
}

.doc .admonitionblock pre {
  font-size: calc(15 / var(--rem-base) * 1rem);
}

.doc .admonitionblock > table {
  table-layout: fixed;
  position: relative;
  width: 100%;
}

.doc .admonitionblock td.content {
  padding: 1rem 1rem 0.75rem;
  background: var(--admonition-background);
  width: 100%;
  word-wrap: anywhere;
}

.doc .admonitionblock .icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: calc(15 / var(--rem-base) * 1rem);
  padding: 0 0.5rem;
  height: 1.25rem;
  line-height: 1;
  font-weight: var(--admonition-label-font-weight);
  text-transform: uppercase;
  border-radius: 0.45rem;
  transform: translate(-0.5rem, -50%);
}

.doc .admonitionblock.caution .icon {
  background-color: var(--caution-color);
  color: var(--caution-on-color);
}

.doc .admonitionblock.important .icon {
  background-color: var(--important-color);
  color: var(--important-on-color);
}

.doc .admonitionblock.note .icon {
  background-color: var(--note-color);
  color: var(--note-on-color);
}

.doc .admonitionblock.tip .icon {
  background-color: var(--tip-color);
  color: var(--tip-on-color);
}

.doc .admonitionblock.warning .icon {
  background-color: var(--warning-color);
  color: var(--warning-on-color);
}

.doc .admonitionblock .icon i {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.doc .admonitionblock .icon i::after {
  content: attr(title);
}

old admonition blocks - end */

/* new admonition blocks - start */

/* attempt at circles behind task numbers */
/*
li::marker {
  display: inline-block;
  background-color: #f0f0f3;
  border-radius: 50%;
  color: #434355;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 24px;
  margin-left: -36px;
  margin-right: 8px;
}
*/
/* comm columns - start */

.doc-home .columns .box {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.2);
  margin: 0 6px 30px;
  position: relative;
  padding: 20px 20px 20px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.doc-home .columns .box img {
  width: 40px;
  margin-bottom: -15px;
}

.doc-home .columns .box a {
  width: 40px;
  font-size: 15px;
  margin-bottom: -15px;
  font-family: var(--body-font-family);
}

.doc-home .columns .box ul {
  padding: 5px;
  margin-left: 15px;
  margin-top: 5px;
}

.doc-home .columns .box li {
  padding: 5px;
  list-style-type: none;
  margin-left: -24px;
  margin-bottom: -10px;
}

.doc-home .columns .box li::marker {
  padding: 5px;
  margin-left: 15px;
}

.doc-home .columns .box h2 {
  margin-top: 25px;
  margin-bottom: 15px;
  /*  margin-left: -18px; */
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 26px;
}

.doc-home .columns .box p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.doc-home .columns .box .button {
  width: calc(100% - 50px);
  padding: 9px 15px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 16px;
}

.doc-home .columns .box .box {
  box-shadow: none;
  padding: 0;
}

.doc-home .columns > a {
  display: block;
  margin: 30px 10px 0;
  color: #fff !important;
  background-color: #000;
}
/*
@media only screen and (min-width: 800px) {
  .columns {
    display: flex;
    /* justify-content: center; */
/*    flex-wrap: wrap;
  }

  .columns .box {
    width: calc(26% - 20px);
  }

  .columns .box:first-child {
    margin-left: 0;
  }

  .columns .box:last-child {
    margin-right: 0;
  }

  .columns > a {
    width: calc(25% - 20px);
  }

  .columns > a:first-child {
    margin-left: 0;
  }

  .columns > a:last-child {
    margin-right: 0;
  }
}
*/
/*
@media only screen and (max-width: 800px) {
  .columns .box {
    margin-bottom: 25px;
    width: 100%;
    display: block;
  }
}
*/
/* comm columns -end */

.doc .admonitionblock {
  margin-top: 1rem;
}

.doc-snippet .admonitionblock {
  margin-top: 1rem;
}

.doc-home .admonitionblock {
  margin-top: 1rem;
}

.doc .admonitionblock > table,
.doc .admonitionblock > table > tbody,
.doc .admonitionblock > table > tbody > tr > td {
  display: block;
  padding-left: 2px; /* for new all-in-one note */
}

.doc-home .admonitionblock > table,
.doc-home .admonitionblock > table > tbody,
.doc-home .admonitionblock > table > tbody > tr > td {
  display: block;
  padding-left: 2px; /* for new all-in-one note */
}

.doc .admonitionblock > table > tbody > tr {
  display: flex;
}

.doc-home .admonitionblock > table > tbody > tr {
  display: flex;
}

.doc .admonitionblock td.icon {
  padding-left: 0.1rem;
  /* padding-right: 0.5rem; */ /* new all-in-one admonition */
}

.doc-home .admonitionblock td.icon {
  padding-left: 0.1rem;
  margin-top: 25px;
  /* padding-right: 0.5rem; */ /* new all-in-one admonition */
}

.doc .admonitionblock td.icon i::before {
  background: no-repeat 0/cover;
  content: "";
  margin-top: -18px; /* for new all-in-one admonition */
  margin-left: 7px; /* for new all-in-one admonition */
  display: block;
  height: 1.875rem;
  width: 1.875rem;
}

.doc-home .admonitionblock td.icon i::before {
  background: no-repeat 0/cover;
  content: "";
  margin-top: -18px; /* for new all-in-one admonition */
  margin-left: 7px; /* for new all-in-one admonition */
  display: block;
  height: 1.875rem;
  width: 1.875rem;
}

.doc .admonitionblock td.content {
  /* border-bottom: 1px solid var(--color-brand-silver); */ /* new all-in-one admonition */
  /* border-right: 1px solid var(--color-brand-silver); */ /* new all-in-one admonition */
  /* border-top: 1px solid var(--color-brand-silver); */ /* new all-in-one admonition */
  flex: 1;
  font-size: 0.8375rem;
  hyphens: auto;
  line-height: 1.6;
  min-width: 0;
  padding: 0.75rem;
  padding-left: 0.3rem;
  border-radius: 5px;
  /* border: 2px solid rgba(33, 126, 231, 0.2); */ /* new all-in-one admonition */
}

.doc-home .admonitionblock td.content {
  /* border-bottom: 1px solid var(--color-brand-silver); */ /* new all-in-one admonition */
  /* border-right: 1px solid var(--color-brand-silver); */ /* new all-in-one admonition */
  /* border-top: 1px solid var(--color-brand-silver); */ /* new all-in-one admonition */
  flex: 1;
  font-size: 0.8375rem;
  hyphens: auto;
  line-height: 1.6;
  min-width: 0;
  padding: 0.75rem;
  padding-left: 0.3rem;
  border-radius: 5px;
  /* border: 2px solid rgba(33, 126, 231, 0.2); */ /* new all-in-one admonition */
}

.doc .admonitionblock td.content > .title {
  display: inline;
  font-style: italic;
}

.doc-home .admonitionblock td.content > .title {
  display: inline;
  font-style: italic;
}

.doc .admonitionblock td.content > .title::after {
  content: "";
  display: table;
}

.doc-home .admonitionblock td.content > .title::after {
  content: "";
  display: table;
}

.doc .admonitionblock td.content::before {
  font-weight: var(--weight-medium);
}

.doc-home .admonitionblock td.content::before {
  font-weight: var(--weight-medium);
}

.doc .admonitionblock.caution > table {
  background-color: transparent;
  border: 2px solid rgba(247, 140, 32, 0.2); /* for new all-in-one note */
  border-left: 6px solid #f78c20; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(247, 140, 32, 0.2);
  border-top: 2px solid rgba(247, 140, 32, 0.2);
  border-right: 2px solid rgba(247, 140, 32, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.caution td.icon i::before {
  background-image: url(../img/caution.svg);
  vertical-align: middle;
}

.doc .admonitionblock.caution td.content::before {
  /* content: "Caution: "; */
  color: #f78c20;
}

.doc .admonitionblock.caution td.content {
  /* border-left: 6px solid #f78c20;
  border-bottom: 2px solid rgba(163, 93, 255, 0.2);
  border-top: 2px solid rgba(163, 93, 255, 0.2);
  border-right: 2px solid rgba(163, 93, 255, 0.2);
  box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.important > table {
  background-color: transparent;
  border: 2px solid rgba(163, 93, 255, 0.2); /* for new all-in-one note */
  border-left: 6px solid #a35dff; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(163, 93, 255, 0.2);
  border-top: 2px solid rgba(163, 93, 255, 0.2);
  border-right: 2px solid rgba(163, 93, 255, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.important td.icon i::before {
  background-image: url(../img/important.svg);
  vertical-align: middle;
}

.doc .admonitionblock.important td.content::before {
  /* content: "Important: "; */
  color: #a35dff;
}

.doc .admonitionblock.important td.content {
  /* border-left: 6px solid #a35dff;
  border-bottom: 2px solid rgba(255, 89, 90, 0.2);
  border-top: 2px solid rgba(255, 89, 90, 0.2);
  border-right: 2px solid rgba(255, 89, 90, 0.2);
  box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.note > table {
  background-color: transparent;
  border: 2px solid rgba(0, 174, 255, 0.2); /* for new all-in-one note */
  border-left: 6px solid #00aeef; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(0, 174, 2319, 0.2);
  border-top: 2px solid rgba(0, 174, 2319, 0.2);
  border-right: 2px solid rgba(0, 174, 2319, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc-home .admonitionblock.note > table {
  background-color: transparent;
  border: 2px solid rgba(0, 174, 255, 0.2); /* for new all-in-one note */
  border-left: 6px solid #00aeef; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(0, 174, 2319, 0.2);
  border-top: 2px solid rgba(0, 174, 2319, 0.2);
  border-right: 2px solid rgba(0, 174, 2319, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc-snippet .admonitionblock.note > table {
  background-color: transparent;
  border: 2px solid rgba(0, 174, 255, 0.2); /* for new all-in-one note */
  border-left: 6px solid #00aeef; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(0, 174, 2319, 0.2);
  border-top: 2px solid rgba(0, 174, 2319, 0.2);
  border-right: 2px solid rgba(0, 174, 2319, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.note td.icon i::before {
  background-image: url(../img/info2.svg);
  vertical-align: middle;
  padding-left: 25px; /* for new all-in-one note */
}

.doc-snippet .admonitionblock.note td.icon i::before {
  background-image: url(../img/info2.svg);
  vertical-align: middle;
  padding-left: 25px; /* for new all-in-one note */
}

.doc-home .admonitionblock.note td.icon i::before {
  background-image: url(../img/info2.svg);
  vertical-align: middle;
  padding-left: 25px; /* for new all-in-one note */
}

.doc .admonitionblock.note td.content::before {
  /* content: "Note: "; */
  color: #00aeef;
}

.doc-snippet .admonitionblock.note td.content::before {
  /* content: "Note: "; */
  color: #00aeef;
}

.doc-home .admonitionblock.note td.content::before {
  /* content: "Note: "; */
  color: #00aeef;
}

.doc .admonitionblock.note td.content {
  /* border-left: 6px solid #00aeef;
  border-bottom: 2px solid rgba(0, 174, 2319, 0.2);
  border-top: 2px solid rgba(0, 174, 2319, 0.2);
  border-right: 2px solid rgba(0, 174, 2319, 0.2);
  box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc-home .admonitionblock.note td.content {
  /* border-left: 6px solid #00aeef;
  border-bottom: 2px solid rgba(0, 174, 2319, 0.2);
  border-top: 2px solid rgba(0, 174, 2319, 0.2);
  border-right: 2px solid rgba(0, 174, 2319, 0.2);
  box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.tip > table {
  background-color: transparent;
  border: 2px solid rgba(6, 191, 127, 0.2); /* for new all-in-one note */
  border-left: 6px solid #06bf7f; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(6, 191, 127, 0.2);
  border-top: 2px solid rgba(6, 191, 127, 0.2);
  border-right: 2px solid rgba(6, 191, 127, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc-snippet .admonitionblock.tip > table {
  background-color: transparent;
  border: 2px solid rgba(6, 191, 127, 0.2); /* for new all-in-one note */
  border-left: 6px solid #06bf7f; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(6, 191, 127, 0.2);
  border-top: 2px solid rgba(6, 191, 127, 0.2);
  border-right: 2px solid rgba(6, 191, 127, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.tip td.icon i::before {
  background-image: url(../img/tip.svg);
  vertical-align: middle;
}

.doc .admonitionblock.tip td.content::before {
  /* content: "Tip: "; */
  color: #06bf7f;
}

.doc .admonitionblock.tip td.content {
  /* border-left: 6px solid #06bf7f;
  border-bottom: 2px solid rgba(6, 191, 127, 0.2);
  border-top: 2px solid rgba(6, 191, 127, 0.2);
  border-right: 2px solid rgba(6, 191, 127, 0.2);
  box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.warning > table {
  background-color: transparent;
  border: 2px solid rgba(255, 89, 90, 0.2); /* for new all-in-one note */
  border-left: 6px solid #ff595a; /* for new all-in-one note */
  border-radius: 5px; /* for new all-in-one note */
  border-bottom: 2px solid rgba(255, 89, 90, 0.2);
  border-top: 2px solid rgba(255, 89, 90, 0.2);
  border-right: 2px solid rgba(255, 89, 90, 0.2);
  /* box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock.warning td.icon i::before {
  background-image: url(../img/warning.svg);
  vertical-align: middle;
}

.doc table.tableblock,
.doc table.tableblock > * > tr > * {
  border-bottom: 1px solid #e1e1e1 !important;
}

.doc .admonitionblock.warning td.content::before {
  /* content: "Warning: "; */
  color: #ff595a;
}

.doc .admonitionblock.warning td.content {
  /* border-left: 6px solid #ff595a;
  border-bottom: 2px solid rgba(254, 201, 67, 0.2);
  border-top: 2px solid rgba(254, 201, 67, 0.2);
  border-right: 2px solid rgba(254, 201, 67, 0.2);
  box-shadow: 0.1px 1px 5px 1px #ccc; */
}

.doc .admonitionblock td.content > :first-child {
  margin-top: 0;
}

/* new admonition blocks - end */

.doc .imageblock {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.doc .imageblock img,
.doc .image > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.doc .image:not(.left):not(.right) > img {
  margin-top: -0.2em;
}

.bordered img {
  border: 1px solid #c1c3c7;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

#preamble .abstract blockquote {
  background: var(--abstract-background);
  border-left: 5px solid var(--abstract-border-color);
  color: var(--abstract-font-color);
  font-size: calc(16 / var(--rem-base) * 1rem);
  padding: 0.75em 1em;
}

.doc .quoteblock,
.doc .verseblock {
  background: var(--quote-background);
  border-left: 5px solid var(--quote-border-color);
  color: var(--quote-font-color);
}

.doc .quoteblock {
  padding: 0.25rem 2rem 1.25rem;
}

.doc .quoteblock .attribution {
  color: var(--quote-attribution-font-color);
  font-size: calc(15 / var(--rem-base) * 1rem);
  margin-top: 0.75rem;
}

.doc .quoteblock blockquote {
  margin-top: 1rem;
}

.doc .quoteblock .paragraph {
  font-style: italic;
}

.doc .quoteblock cite {
  padding-left: 1em;
}

.doc .verseblock {
  font-size: 1.15em;
  padding: 1rem 2rem;
}

.doc .verseblock pre {
  font-family: inherit;
  font-size: inherit;
}

.doc ol,
.doc ul {
  margin: 0;
  padding: 0 0 0 2rem;
}

.doc ul.checklist,
.doc ul.none,
.doc ol.none,
.doc ul.no-bullet,
.doc ol.unnumbered,
.doc ul.unstyled,
.doc ol.unstyled {
  list-style-type: none;
}

.doc ul.no-bullet,
.doc ol.unnumbered {
  padding-left: 1.25rem;
}

.doc ul.unstyled,
.doc ol.unstyled {
  padding-left: 0;
}

.doc ul.circle {
  list-style-type: square;
}

.doc ul.disc {
  list-style-type: square;
}

.doc ul.square {
  list-style-type: square;
}

.doc ol.arabic {
  list-style-type: decimal;
}

.doc ol.decimal {
  list-style-type: decimal-leading-zero;
}

.doc ol.loweralpha {
  list-style-type: lower-alpha;
}

.doc ol.upperalpha {
  list-style-type: upper-alpha;
}

.doc ol.lowerroman {
  list-style-type: lower-roman;
}

.doc ol.upperroman {
  list-style-type: upper-roman;
}

.doc ol.lowergreek {
  list-style-type: lower-greek;
}

.doc ul.checklist {
  padding-left: 0.5rem;
}

.doc ul.checklist p > i.fa-check-square-o:first-child,
.doc ul.checklist p > i.fa-square-o:first-child {
  display: inline-flex;
  justify-content: center;
  width: 1.25rem;
}

.doc ul.checklist i.fa-check-square-o::before {
  content: "\2713";
}

.doc ul.checklist i.fa-square-o::before {
  content: "\274f";
}

.doc .dlist .dlist,
.doc .dlist .olist,
.doc .dlist .ulist,
.doc .olist .dlist,
.doc .olist .olist,
.doc .olist .ulist,
.doc .ulist .dlist,
.doc .ulist .olist,
.doc .ulist .ulist {
  margin-top: 0.5rem;
}

.doc .olist li,
.doc .ulist li {
  margin-bottom: 0.5rem;
}

.doc .ulist .listingblock,
.doc .olist .listingblock,
.doc .admonitionblock .listingblock {
  padding: 0;
}

.doc .admonitionblock .title,
.doc .exampleblock .title,
.doc .imageblock .title,
.doc .literalblock .title,
.doc .listingblock .title,
.doc .openblock .title,
.doc .tableblock caption {
  color: var(--caption-font-color);
  font-size: calc(16 / var(--rem-base) * 1rem);
  font-weight: var(--caption-font-weight);
  font-style: italic;
  hyphens: none;
  letter-spacing: 0.01em;
  padding-bottom: 0.075rem;
  text-align: left;
}

.doc .imageblock .title {
  margin-top: 0.5rem;
  padding-bottom: 0;
}

.doc .exampleblock > .content {
  background: var(--example-background);
  border: 0.25rem solid var(--example-border-color);
  border-radius: 0.5rem;
  padding: 0.75rem;
}

.doc .exampleblock > .content > :first-child {
  margin-top: 0;
}

.doc .sidebarblock {
  background: var(--sidebar-background);
  border-radius: 0.75rem;
  padding: 0.75rem 1.5rem;
}

.doc-home .sidebarblock {
  background: #e1e1e1;
  background: var(--sidebar-background);
  border-radius: 0.75rem;
  border: solid 1px lightgrey;
  padding: 0.75rem 1.5rem;
  width: 99.5%;
}

.doc .sidebarblock > .content > .title {
  /* font-size: calc(22.5 / var(--rem-base) * 1rem); */
  font-size: 0.9rem;
  font-weight: var(--alt-heading-font-weight);
  line-height: 1.3;
  margin-bottom: -0.3em;
  text-align: left;
}

.doc .sidebarblock > .content > :not(.title):first-child {
  margin-top: 0;
}

/* NEEDS REVIEW prevent pre in table from causing article to exceed bounds */
.doc .tableblock pre,
.doc .listingblock.wrap pre {
  white-space: pre-wrap;
}

.doc pre.highlight code,
.doc .listingblock pre:not(.highlight),
.doc .literalblock pre {
  background: var(--pre-background);
  /* box-shadow: inset 0 0 1.75px var(--pre-border-color); */
  display: block;
  overflow-x: auto;
  padding: 0.75rem;
  border: lightgrey solid 0.5px;
  border-radius: 5px;
  box-shadow: 0.1px 1px 3px 0 #ccc;
}

.doc pre.highlight {
  position: relative;
}

.doc .source-toolbox {
  display: flex;
  visibility: hidden;
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  color: var(--pre-annotation-font-color);
  font-family: var(--body-font-family);
  font-size: calc(13.5 / var(--rem-base) * 1rem);
  line-height: 1;
}

.doc .listingblock:hover .source-toolbox {
  visibility: visible;
}

.doc .source-toolbox .source-lang {
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-size: 0.96em;
  line-height: 1.0425;
}

.doc .source-toolbox > :not(:last-child)::after {
  content: "|";
  letter-spacing: 0;
  padding: 0 1ch;
}

.doc .source-toolbox .copy-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  outline: none;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  width: 1em;
  height: 1em;
}

.doc .source-toolbox .copy-icon {
  flex: none;
  width: inherit;
  height: inherit;
}

.doc .source-toolbox img.copy-icon {
  filter: invert(50.2%);
}

.doc .source-toolbox svg.copy-icon {
  fill: currentColor;
}

.doc .source-toolbox .copy-toast {
  flex: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  margin-top: 1em;
  background-color: var(--doc-font-color);
  border-radius: 0.25em;
  padding: 0.5em;
  color: var(--color-white);
  cursor: auto;
  opacity: 0;
  transition: opacity 0.5s ease 0.75s;
}

.doc .source-toolbox .copy-toast::after {
  content: "";
  position: absolute;
  top: 0;
  width: 1em;
  height: 1em;
  border: 0.55em solid transparent;
  border-left-color: var(--doc-font-color);
  transform: rotate(-90deg) translateX(50%) translateY(50%);
  transform-origin: left;
}

.doc .source-toolbox .copy-button.clicked .copy-toast {
  opacity: 1;
  transition: none;
}

.doc .language-console .hljs-meta {
  user-select: none;
}

.doc .dlist dt {
  font-style: italic;
}

.doc .dlist dd {
  margin: 0 0 0.25rem 1.5rem;
}

.doc .dlist dd:last-of-type {
  margin-bottom: 0;
}

.doc td.hdlist1,
.doc td.hdlist2 {
  padding: 0.5rem 0 0;
  vertical-align: top;
}

.doc tr:first-child > .hdlist1,
.doc tr:first-child > .hdlist2 {
  padding-top: 0;
}

.doc td.hdlist1 {
  font-weight: var(--body-font-weight-bold);
  padding-right: 0.25rem;
}

.doc td.hdlist2 {
  padding-left: 0.25rem;
}

.doc .colist {
  font-size: calc(16 / var(--rem-base) * 1rem);
  margin: 0.25rem 0 -0.25rem;
}

.doc .colist > table > tr > :first-child,
.doc .colist > table > tbody > tr > :first-child {
  padding: 0.25em 0.5rem 0;
  vertical-align: top;
}

.doc .colist > table > tr > :last-child,
.doc .colist > table > tbody > tr > :last-child {
  padding: 0.25rem 0;
}

.doc .conum[data-value] {
  border: 1px solid currentColor;
  border-radius: 100%;
  display: inline-block;
  font-family: var(--body-font-family);
  font-size: calc(13.5 / var(--rem-base) * 1rem);
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  width: 1.25em;
  height: 1.25em;
  letter-spacing: -0.25ex;
  text-indent: -0.25ex;
}

.doc .conum[data-value]::after {
  content: attr(data-value);
}

.doc .conum[data-value] + b {
  display: none;
}

.doc hr {
  border: solid var(--section-divider-color);
  border-width: 2px 0 0;
  height: 0;
}

.doc b.button {
  white-space: nowrap; /* effectively ignores hyphens setting */
}

.doc b.button::before {
  content: "[";
  padding-right: 0.25em;
}

.doc b.button::after {
  content: "]";
  padding-left: 0.25em;
}

.doc kbd {
  display: inline-block;
  font-size: calc(12 / var(--rem-base) * 1rem);
  background: var(--kbd-background);
  border: 1px solid var(--kbd-border-color);
  border-radius: 0.25em;
  box-shadow: 0 1px 0 var(--kbd-border-color), 0 0 0 0.1em var(--body-background) inset;
  padding: 0.25em 0.5em;
  vertical-align: 0.05rem;
  white-space: nowrap; /* effectively ignores hyphens setting */
}

.doc kbd,
.doc .keyseq {
  line-height: 1;
}

.doc .keyseq {
  font-size: calc(16 / var(--rem-base) * 1rem);
}

.doc .keyseq kbd {
  margin: 0 0.125em;
}

.doc .keyseq kbd:first-child {
  margin-left: 0;
}

.doc .keyseq kbd:last-child {
  margin-right: 0;
}

.doc .menuseq,
.doc .path {
  hyphens: none;
}

.doc .menuseq i.caret::before {
  content: "\203a";
  font-size: 1.1em;
  font-weight: var(--body-font-weight-bold);
  line-height: calc(1 / 1.1);
}

.doc .nowrap {
  white-space: nowrap;
}

.doc .nobreak {
  hyphens: none;
  word-wrap: normal;
}

/* Privileges table in security-matrix.md */

.doc table.wide_table > caption {
  /* font-size: larger; */
  margin-bottom: 2em;
  border-bottom: #ccc;
  border-bottom-style: double;
}

.doc table.wide_table > tbody > tr > td {
  border-right: 1px solid #ddd;
}

.doc table.wide_table,
th,
td {
  border: none !important;
}

.doc table.wide_table > thead > tr > th {
  color: initial;
  background-color: initial;
}

.doc table.wide_table > thead > tbody {
  border: 1px;
}

.doc table.wide_table {
  max-width: initial;
  margin-bottom: initial;
  table-layout: initial;
  column-width: initial;
  word-break: initial;
  margin-top: 50px;
  page: wide_table;
  font-size: 13px;
}

.doc th.rotate {
  /* Something you can count on */
  height: 160px;
  white-space: nowrap;
  /* font-size: x-small; */
}

.doc th.rotate > div {
  transform:
    /* Magic Numbers */
    translate(-10px, 51px)
    /* 45 is really 360 - 45 */
    rotate(315deg);
  width: 30px;
}

.doc th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

/* tooltip css - start */

/* ts edition comparison guide css - start */

.scrollable-wide {
  overflow-x: auto;
}

.scrollable-long {
  overflow-y: auto;
  height: 576px;
}

.is-this-helpful-box-bottom {
  /* display: inline-block; */
  /* border: solid 1px black; */
  border-radius: 8px;
  width: 355px;
  margin-top: 15px;
  margin-bottom: -5px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 500;
  /* position: fixed; */
  /* bottom: 20px; */
  z-index: 1;
  /* opacity: 0; */
  /* -webkit-animation-duration: 1s; */
  /* animation-duration: 1s; */
  animation: fadein linear 2s;
  -webkit-animation: fadein 2s linear;
  -moz-animation: fadein linear 2s;
  -o-animation: fadein linear 2s;
  -ms-animation: fadein linear 2s;
}

.feedback-btn-sm {
  padding: 5px 10px;
  margin-left: 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 10px;
}

.feedback-btn-outline-primary {
  color: #4e55fd !important;
  background-color: transparent;
  background-image: none;
  border-color: #4e55fd;
  transition: 0.6s;
  animation: fadein linear 2s;
  -webkit-animation: fadein 2s linear;
  -moz-animation: fadein linear 2s;
  -o-animation: fadein linear 2s;
  -ms-animation: fadein linear 2s;
}

.feedback-btn-outline-primary:hover {
  color: #fff !important;
  background-color: #4e55fd;
  border-color: #4e55fd;
  transition: 0.3s;
}

.feedback-btn-outline-primary:focus,
.feedback-btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* popup css - start */

.modal {
  display: none;
}

.vanilla-modal .modal {
  display: block;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s, z-index 0s 0.2s;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.vanilla-modal .modal > * {
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  text-align: left;
}

.vanilla-modal .modal::before {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.vanilla-modal.modal-visible .modal {
  z-index: 99;
  opacity: 1;
  transition: opacity 0.2s;
}

.modal-inner {
  position: relative;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: -1;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s, z-index 0s 0.2s;
}

.modal-visible .modal-inner {
  z-index: 100;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s, transform 0.2s;
}

[data-modal-close] {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  opacity: 0.5;
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
}

iframe {
  width: 700px;
  height: 300px;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
}

/* popup styles - end */

/* ts edition comparison guide css - end */

/* END: Privileges table in security-matrix.md */

/* https://css-tricks.com/rotated-table-column-headers/ */
