/* .body .container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-height: 100vh;
} */

/* added from centered default ui */
.container {
  margin: 0 auto;
  max-width: var(--width-container);
  padding: 0 var(--width-container-gutter);
  width: 100%;
}

.home .ulist li {
  margin-bottom: 0.5rem;
}

.home {
  background-color: white;
  flex: 1;
  min-height: 100vh;
}

.home .box-button p {
  margin: 0;
  color: #444;
  font-size: 16px;
}

.home .paragraph {
  margin-top: 1.25rem;
}

.home .paragraph.hint {
  margin-top: 2rem;
  font-size: 0.9375rem;
}

.home .btn-outline-primary {
  transition: 0.3s background ease-in-out !important;
  animation: fadein linear 0s !important;
  -webkit-animation: fadein 0s linear !important;
  -moz-animation: fadein linear 0s !important;
  -o-animation: fadein linear 0s !important;
  -ms-animation: fadein linear 0s !important;
  margin-bottom: 25px !important;
}

.home .button-box {
  display: inline-block, flex;
  margin: 0 auto !important;
  /* margin-top: 25px; */
  /* margin-bottom: 55px; */
  /* padding-top: 15px; */
  font-size: 14px;
  font-weight: 400;
  position: relative !important;
  z-index: 1;
  animation: fadein 0s linear !important;
  -webkit-animation: fadein 0s linear !important;
  border: 5px;
  /*  display: flex; */
  justify-content: center;
}

.home h1,
.home h2,
.home h3 {
  line-height: 1.2;
  margin: 0;
  color: #444;
}

.home h1.page {
  font-size: 1.8rem;
  margin-top: 3.5rem;
  /* text-align: center; */
  text-align: left;
}

.home #preamble {
  margin: 2rem 0 3rem;
  margin-left: 10px;
  font-size: 0.9rem;
  /* text-align: center; */
  text-align: center;
}

.doc-home #preamble {
  margin: 2rem 0 3rem;
  margin-left: 10px;
  font-size: 0.9rem;
  /* text-align: center; */
  text-align: left;
}

.home .button::before {
  content: "[";
  padding-right: 0.25em;
}

.home .button::after {
  content: "]";
  padding-left: 0.25em;
}

.home .card ul,
.home .tile ul {
  line-height: 1.25;
  list-style: none;
  margin: 0;
  padding: 0;
}

.home .card li,
.home .tile li {
  padding: 0.25em 0;
}

.home .card a,
.home .tile a {
  text-decoration: none;
  position: relative;
}

.home .card a::before,
.home .tile a::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: currentColor;
  transform: scale3d(0, 0.9999, 0.9999);
  transition-property: transform;
  transition-duration: 0.3s;
}

.home .card a:focus::before,
.home .card a:hover::before,
.home .tile a:focus::before,
.home .tile a:hover::before {
  transform: scale3d(1, 0.9999, 0.9999);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

.home .conceal-title > h2 {
  display: none;
}

.home .cards > .sectionbody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -0.75rem 0 0 -0.75rem;
}

.home .card {
  color: #fff;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin: 0.75rem 0 0 0.75rem;
  min-width: 0;
  padding: 1.25rem;
  border-radius: 9px;
}

.home .card:nth-of-type(1) {
  background-color: var(--color-brand-pink);
  border-radius: 9px;
}

.home .card:nth-of-type(2) {
  background-color: var(--color-brand-green);
  border-radius: 9px;
}

.home .card:nth-of-type(3) {
  background-color: var(--color-brand-light-blue);
  border-radius: 9px;
}

.home .card:nth-of-type(4) {
  background-color: var(--color-brand-orange);
  border-radius: 9px;
}

/* changes height of persona titles */

.home .card h3 {
  /*  font-size: 1.5rem; */
  top: -3%;
  /*  line-height: 2rem; */
  padding: 0 0 1rem;
  position: relative;
}

.home .card h3::after {
  content: "";
  display: block;
  position: absolute;
  max-width: 5.75rem;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 5px;
  border: 1px solid currentColor;
  width: 25%;
}

.home .card .paragraph {
  font-weight: var(--weight-light);
  margin-top: 0.1rem;
  /*  flex: 1 0 auto; */ /* NOTE must specify flex-basis for IE 11 */
}

.home .card .ulist {
  font-weight: 500;
  /*  margin: 1.5rem 0 0.5rem; */
  /* overflow-wrap: normal; */
  word-wrap: normal; /* NOTE not sure why this is required, but without it, list items get spaced apart */
}

.home .card a,
.home .tile .title a {
  color: inherit;
}

/*
.home .card a::after {
  content: "\00a0>";
  width: 0;
  display: inline-block;
}
*/

.home .tiles {
  font-size: 1.125rem;
  margin-top: 2.5rem;
}

.home .tiles > h2 {
  font-size: 2rem;
  hyphens: auto;
  padding-bottom: 3.125rem;
  text-align: center;
}

.home .tiles > h2::after {
  content: "";
  display: block;
  background-color: currentColor;
  width: 5.625rem;
  height: 1px;
  margin: 0 auto;
  margin-bottom: -1px;
  position: relative;
  top: 1.375rem;
}

.home .tiles > .sectionbody {
  display: flex;
  flex-wrap: wrap;
}

.home .tile {
  flex: 1;
  margin-bottom: 0.75rem;
  min-width: 0;
  flex-basis: auto;
  width: 50%;
}

.home .tile .title {
  font-weight: var(--weight-bold);
  margin-bottom: 0.25rem;
}

.doc-home #preamble .paragraph {
  text-align: left;
}

@media screen and (min-width: 769px) {
  .home h1.page,
  .home #preamble {
    /* margin-left: 10px; */
    margin-right: 0.5rem;
    margin-bottom: 0;
    text-align: center;
  }

  .home .cards > .sectionbody {
    flex-direction: row;
  }

  .home .card {
    flex-basis: 0;
  }

  .home .cards-4 .card {
    flex-basis: calc(50% - 0.75rem);
  }

  .home .tiles {
    padding: 0 10%;
  }

  .home .tiles > .sectionbody {
    margin: 0 -0.5rem;
  }

  .home .tile {
    flex-basis: 0;
    margin-bottom: 0;
    padding: 0 0.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .home .cards-4 .card {
    flex-basis: 0;
    border-radius: 9px;
  }
}

/** CSS rules for tiles on the tutorial list page **/

/* override existing */
.tutorials.body.tiles .doc {
  justify-content: flex-start;
  margin-right: 0;
}

.tutorials .container {
  min-height: 100%;
}

/* 1 column layout on small screens */
@media screen and (max-width: 768px) {
  .tutorials.body.tiles .sect1 {
    flex-basis: 100%;
  }
}

/* 2 column layout on large screens */
@media screen and (min-width: 769px) {
  .tutorials.body.tiles .sect1 {
    flex-basis: calc(45% - 20px);
  }
}

@media screen and (min-width: 659px) {
  .home #preamble {
    text-align: left;
    margin-left: 13px;
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: left;
    margin-left: 13px;
  }
}

@media screen and (min-width: 496px) {
  .home #preamble {
    text-align: left;
    margin-left: 13px;
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: left;
    margin-left: 13px;
  }
}

@media screen and (min-width: 481px) {
  .home #preamble {
    text-align: left;
    margin-left: 13px;
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: left;
    margin-left: 13px;
  }
}

@media screen and (min-width: 375px) {
  .home #preamble {
    text-align: left;
    margin-left: 13px;
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: left;
    margin-left: 13px;
  }
}

.tutorials.body.tiles .sectionbody {
  /* add padding immediately inside the card */
  display: flex;
  height: calc(100% - 55px);
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0.9375rem;
}

/* target the tutorial title */
.tutorials.body.tiles .sectionbody > div.paragraph {
  font-size: 1.3rem;
}

mark {
  /* background: #f4f8fd; */
}

.tutorials.body.tiles .sect1 {
  border: 1px solid var(--color-brand-silver);
  margin: 0 0 1.25rem 0;
  /* override property defined in .body.tiles .sect1 */
  padding-right: 0;
  margin-right: 1.25rem;
}

.tutorials.body.tiles .sect1 > h2 {
  color: white;
  line-height: 2.2rem;
  min-width: 100%;
  text-align: center;
}

.tutorials.body.tiles .sect2 {
  display: flex;
  flex-direction: column;
}

.tutorials.body.tiles .sect2.title {
  height: 100%;
}

.tutorials.body.tiles .links + .sect3 {
  margin-bottom: 1.25rem;
}

/* fix the height. some titles span over 2 lines */
.tutorials.body.tiles .title {
  /* height: 62px; */
}

/* fix the height. tutorial summaries vary in length */
.tutorials.body.tiles .sect2 .summary p {
  height: 100%;
}

/* use flexbox to lay out the additional links */
.tutorials.body.tiles .links p {
  display: flex;
  flex-wrap: wrap;
}

/* each link takes 1/3 of the card width */
.tutorials.body.tiles .links p a {
  flex-basis: 33%;
  margin: 10px 0;
  text-align: center;
}

/* because `justify-self: flex-end` is being ignored in modern browsers */
/* https://stackoverflow.com/a/49658476/1908348 */
.tutorials.body.tiles .sect4:last-child {
  margin-top: auto;
}

.tutorials.body.tiles .sect1.developer > h2 {
  background-color: #00b0dc;
}

.tutorials.body.tiles .sect1.architect > h2 {
  background-color: #00bdbd;
}

.tutorials.body.tiles .sect1.devops > h2 {
  background-color: #ff983a;
}

/* layout tutorial languages and components */
.tutorials.body.tiles .sect3 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tutorials.body.tiles .sect2.metadata {
  align-content: flex-end;
  border-top: 1px solid var(--color-brand-silver);
  display: flex;
  justify-content: flex-end;
}

.metadata {
  padding: 0.625rem;
}

.metadata h3 {
  font-size: 1rem;
  font-weight: normal;
}

.tutorials.body.tiles .sect3 h4 {
  font-size: 1.1rem;
  font-weight: normal;
  margin-top: 0.5rem;
}

.tutorials.body.tiles .sect4 {
  display: flex;
  height: 9.563rem;
  padding: 0.625rem;
  text-align: center;
}

.tutorials.body.tiles .sect4 .ulist ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tutorials.body.tiles .sect5 {
  margin: 5px 0;
  width: 50%;
}

.tutorials.body.tiles .sect5 h6 {
  font-size: 1rem;
  margin: 0;
}

.tutorials .banner {
  background: linear-gradient(to right, var(--color-brand-purple) 0%, var(--color-brand-light-blue) 100%);
  color: white;
  height: calc(440px - var(--height-navbar));
}

.tutorials .banner h1 {
  font-size: 2.375rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.tutorials .banner h2 {
  font-weight: 300;
  font-size: 1.75rem;
  margin: 0;
  padding: 14px 0 17px 0;
}

.tutorials .banner-content {
  display: flex;
  align-items: center;
}

.tutorials footer {
  margin-top: 3rem;
}

.searchbar {
  background-color: var(--color-navbar-bg);
  height: 4.2rem;
  line-height: 1;
}

.searchbar .container {
  display: flex;
  align-items: center;
  height: inherit;
}

.searchbar,
.searchbar a {
  color: var(--color-navbar-text);
}

.searchbar-menu {
  display: flex;
  flex-grow: 1;
}

.searchbar-start {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 1.0625rem;
}

.searchbar-start .searchbar-item.search {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  padding-right: 1.25rem;
}

.searchbar-start input.query {
  flex-grow: 1;
  background-color: var(--color-navbar-bg);
  color: inherit;
  padding: 0;
  font-size: 1.3rem;
  font-weight: var(--weight-light);
  border: 0;
}

/* .home .content {
  display: grid;
  grid-template-rows: 1fr auto;
} */

.home .footer {
  background-color: var(--footer-background);
  color: var(--footer-font-color);
  font-size: calc(15 / var(--rem-base) * 1rem);
  line-height: var(--footer-line-height);
  padding: 1.5rem;
}

.home .footer p {
  margin: 0.5rem 0;
}

.home .footer a {
  color: var(--footer-link-font-color);
}

/* sidebar - start */

.doc-home .sidebarblock {
  background: #e1e1e1;
  background: var(--sidebar-background);
  border-radius: 0.75rem;
  border: solid 1px lightgrey;
  padding: 0.75rem 1.5rem;
}

/* sidebar - end */

/* comm columns - start */

.doc .columns .box {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.2);
  margin: 0 0 30px;
  position: relative;
  padding: 30px 25px 130px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.doc .columns .box img {
  width: 40px;
  margin-bottom: -15px;
}

.doc .columns .box a {
  width: 40px;
  font-size: 16px;
  margin-bottom: -15px;
  font-family: var(--body-font-family);
}

.doc .columns .box ul {
  padding: 5px;
  margin-left: 15px;
}

.doc .columns .box li {
  padding: 5px;
  list-style-type: none;
  margin-left: -24px;
}

.doc .columns .box li::marker {
  padding: 5px;
  margin-left: 15px;
}

.doc .columns .box h2 {
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: -18px;
  /* font-weight: 500; */
  font-size: 20px;
  line-height: 26px;
}

.doc .columns .box-button h2 {
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: -18px;
  /* font-weight: 500; */
  font-size: 20px;
  line-height: 26px;
}

.doc .columns .box p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.doc .columns .box-button p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.doc .columns .box .button {
  width: calc(100% - 50px);
  padding: 9px 15px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 16px;
}

.doc .columns .box .box {
  box-shadow: none;
  padding: 0;
}
/* 2nd column boxes - start */
.doc .columns .box2 {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.2);
  margin: 0 0 30px;
  position: relative;
  padding: 30px 25px 130px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.doc .columns .box2 img {
  width: 40px;
  margin-bottom: -15px;
}

.doc .columns .box2 a {
  width: 40px;
  font-size: 16px;
  margin-bottom: -15px;
  font-family: var(--body-font-family);
}

.doc .columns .box2 ul {
  padding: 5px;
  margin-left: 15px;
}

.doc .columns .box2 li {
  padding: 5px;
  list-style-type: none;
  margin-left: -24px;
}

.doc .columns .box2 li::marker {
  padding: 5px;
  margin-left: 15px;
}

.doc .columns .box2 h2 {
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: -18px;
  /* font-weight: 500; */
  font-size: 20px;
  line-height: 26px;
}

.doc .columns .box2 p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.doc .columns .box2 .button {
  width: calc(100% - 50px);
  padding: 9px 15px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 16px;
}

.doc .columns .box2 .box2 {
  box-shadow: none;
  padding: 0;
}
/* 2nd column boxes - end */
.doc .columns > a {
  display: block;
  margin: 30px 10px 0;
  color: #fff !important;
  background-color: #000;
}

@media only screen and (min-width: 1024px) {
  .columns {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }

  .columns .box {
    width: calc(50% - 20px);
  }

  .columns .box:first-child {
    margin-left: 0;
  }

  .columns .box:last-child {
    margin-right: 0;
  }

  .columns .box-button {
    width: calc(50% - 20px);
  }

  .columns .box-button:first-child {
    margin-left: 0;
  }

  .columns .box-button:last-child {
    margin-right: 0;
  }

  .home #preamble {
    text-align: center;
    /* margin-left: 13px; */
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: center;
    /* margin-left: 13px; */
  }
}

/* box-button 1024 styles - start */
@media only screen and (min-width: 1024px) {
  .box-button-columns {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    margin: 0 auto;
    margin-left: 144px;
    width: 85%;
  }

  .box-button-columns .box-button {
    width: calc(50% - 20px);
  }

  .box-button-columns .box-button:first-child {
    margin-left: 5px;
  }

  .box-button-columns .box-button:last-child {
    margin-right: 0;
  }

  /* box-button 1024 styles - end */

  /* 2nd column box dimensions - start */
  .columns .box2 {
    width: calc(50% - 20px);
  }

  .columns .box2:first-child {
    margin-left: 0;
  }

  .columns .box2:last-child {
    margin-right: 0;
  }
  /* 2nd column box dimensions - end */

  .columns > a {
    width: calc(25% - 20px);
  }

  .columns > a:first-child {
    margin-left: 0;
  }

  .columns > a:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 800px) {
  .columns .box {
    margin-bottom: 25px;
    width: 100%;
    display: block;
  }
}

/* 2nd column box media query - start */
@media only screen and (max-width: 800px) {
  .columns .box2 {
    margin-bottom: 25px;
    width: 100%;
    display: block;
  }

  .home p {
    margin: 0;
    color: #444;
    text-align: left;
  }
}
/* 2nd column box media query - end */

/* comm columns -end */

/* comm columns - start */

.home .columns .box {
  text-align: left;
  background-color: #fff;
  /* -webkit-box-shadow: 0 28px 45px rgb(0 0 0 / 20%); */
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  margin: 0 6px 30px;
  position: relative;
  padding: 20px 20px 20px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

/* landing page box button styles - start */

.box-button {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  margin: 0 6px 30px;
  position: relative;
  padding: 20px 20px 20px;
  border: 1px solid lightgray;
  border-radius: 15px;
  transition: transform 0.2s;
}

.box-button h2 {
  margin-top: 25px;
  margin-bottom: 1px;
  /*  margin-left: -18px; */
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 26px;
}

.box-button h2:hover {
  text-decoration: none;
}

.box-button:hover {
  background-color: #fff;
  /* background-image: url("../../_/img/important.svg"); */
  border-color: #4e55fd;
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.box-button:active { transform: translateY(4px); }

label {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 900;
  color: inherit;
  line-height: 29px;
  cursor: inherit;
}

@media screen and (min-width: 1200px)
  .box-button > label {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 900;
  color: inherit;
  line-height: 29px;
  cursor: inherit;
}

@media screen and (min-width: 1200px) {
  .home #preamble {
    text-align: center;
    /* margin-left: 13px; */
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: center;
    /* margin-left: 13px; */
  }
}

@media screen and (min-width: 1216px) {
  .home #preamble {
    text-align: center;
    margin-left: 0;
  }

  .home h1.page {
    font-size: 1.8rem;
    margin-top: 3.5rem;
    text-align: center;
    /* margin-left: 13px; */
  }
}

.panel {
  margin: 200px;
  width: 400px;
  box-sizing: border-border-box;
  padding: 20px;
  background-color: #333;
  border-radius: 6px;
  display: block;
  text-decoration: none !important;
}

.panel:hover {
  background-color: #4e55fd;
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.panel:active { transform: translateY(4px); }

.panel-2 {
  /* margin: 200px; */
  /* width: 100%; */
  height: 100%;
  box-sizing: border-border-box;
  /* padding: 20px; */
  /* background-color: #333; */
  border-radius: 6px;
  display: block;
  text-decoration: none !important;
}

/* landing page box button styles - end */

.home .columns .box-home {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.2);
  margin: 0 6px 30px;
  position: relative;
  padding: 20px 20px 20px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.home .columns .box img {
  width: 40px;
  margin-bottom: -15px;
}

.home .columns .box-button img {
  width: 40px;
  margin-bottom: -15px;
}

.home .box-button-columns .box-button img {
  width: 40px;
  margin-bottom: -15px;
}

.home .columns .box-home img {
  width: 40px;
  margin-bottom: -15px;
}

.image-small {
  width: 22px;
}

.home .columns .box a {
  width: 40px;
  font-size: 15px;
  margin-bottom: -15px;
  font-family: var(--body-font-family);
}

.home .columns .box-home a {
  width: 40px;
  font-size: 15px;
  margin-bottom: -15px;
  font-family: var(--body-font-family);
}

.home .columns .box .image-small {
  width: 22px;
  vertical-align: middle;
  margin-left: 1px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.home .columns .box-home .image-small {
  width: 22px;
  vertical-align: middle;
  margin-left: 1px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.home .columns .box ul {
  padding: 5px;
  margin-left: 15px;
  margin-top: 0;
}

.home .columns .box-home ul {
  padding: 5px;
  margin-left: 15px;
  /* margin-top: 5px; */
}

.home .columns .box li {
  padding: 5px;
  list-style-type: none;
  margin-left: -24px;
  margin-bottom: -10px;
}

.home .columns .box-home li {
  padding: 5px;
  list-style-type: none;
  margin-left: -24px;
  margin-bottom: -10px;
}

.home .columns .box li::marker {
  padding: 5px;
  margin-left: 15px;
}

.home .columns .box-home li::marker {
  padding: 5px;
  margin-left: 15px;
}

.home .columns .box h2 {
  margin-top: 25px;
  margin-bottom: 1px;
  /*  margin-left: -18px; */
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 26px;
}

.home .columns .box-home h2 {
  margin-top: 25px;
  margin-bottom: 5px;
  /*  margin-left: -18px; */
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 26px;
}

.home .columns .box p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.home .columns .box-home p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.home .columns .box .button {
  width: calc(100% - 50px);
  padding: 9px 15px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 16px;
}

.home .columns .box-home .button {
  width: calc(100% - 50px);
  padding: 9px 15px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 16px;
}

.home .columns .box .box {
  box-shadow: none;
  padding: 0;
}

.home .columns .box-home .box-home {
  box-shadow: none;
  padding: 0;
}

.home .columns > a {
  display: block;
  margin: 30px 10px 0;
  color: #fff !important;
  background-color: #000;
}

/* 2nd column home box styles - start */
.home .columns .box2 {
  text-align: left;
  background-color: #fff;
  box-shadow: 0 28px 45px rgba(0, 0, 0, 0.2);
  margin: 0 6px 30px;
  position: relative;
  padding: 20px 20px 20px;
  border: 1px solid lightgray;
  border-radius: 15px;
}

.home .columns .box2 img {
  width: 40px;
  margin-bottom: -15px;
}

.home .columns .box2 a {
  width: 40px;
  font-size: 15px;
  margin-bottom: -15px;
  font-family: var(--body-font-family);
}

.home .columns .box2 .image-small {
  width: 22px;
  vertical-align: middle;
  margin-left: 1px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.home .columns .box2 ul {
  padding: 5px;
  margin-left: 15px;
  margin-top: 5px;
}

.home .columns .box2 li {
  padding: 5px;
  list-style-type: none;
  margin-left: -24px;
  margin-bottom: -10px;
}

.home .columns .box2 li::marker {
  padding: 5px;
  margin-left: 15px;
}

.home .columns .box2 h2 {
  margin-top: 25px;
  margin-bottom: 15px;
  /*  margin-left: -18px; */
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 26px;
}

.home .columns .box2 p {
  font-size: 16px;
  font-weight: 300;
  color: #777;
}

.home .columns .box2 .button {
  width: calc(100% - 50px);
  padding: 9px 15px;
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 16px;
}

.home .columns .box2 .box2 {
  box-shadow: none;
  padding: 0;
}

/* 2nd column home box styles - end */

@media only screen and (min-width: 1216px) {
  .columns {
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
  }

  .columns .box {
    width: calc(25% - 20px);
  }

  .columns .box-button {
    width: calc(25% - 20px);
  }

  .columns .box2 {
    width: calc(25% - 20px);
  }

  .columns .box-home {
    width: calc(25% - 20px);
  }

  .columns .box:first-child {
    margin-left: 0;
  }

  .columns .box-button:first-child {
    margin-left: 0;
  }

  .columns .box2:first-child {
    margin-left: 0;
  }

  .columns .box-home:first-child {
    margin-left: 0;
  }

  .main-home .columns .box:first-child {
    margin-left: 0;
  }

  .columns .box:last-child {
    margin-right: 0;
  }

  .columns .box-button:last-child {
    margin-right: 0;
  }

  .columns .box2:last-child {
    margin-right: 0;
  }

  .columns .box-home:last-child {
    margin-right: 0;
  }

  .columns > a {
    width: calc(25% - 20px);
  }

  .columns > a:first-child {
    margin-left: 0;
  }

  .columns > a:last-child {
    margin-right: 0;
  }
}

/* box-button styles - start */

@media only screen and (min-width: 1216px) {
  .box-button-columns {
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
    margin: 0 auto;
    margin-left: 146px;
    width: 85%;
  }

  .box-button-columns .box-button {
    width: calc(16% - 20px);
  }

  .box-button-columns .box-button:first-child {
    /* margin-left: 0; */
    margin-left: 20px;
  }

  .box-button-columns .box-button:last-child {
    margin-right: 0;
  }

  .box-button-columns .box2:last-child {
    margin-right: 0;
  }

  .box-button-columns .box-home:last-child {
    margin-right: 0;
  }

  .box-button-columns > a {
    width: calc(25% - 20px);
  }

  .box-button-columns > a:first-child {
    margin-left: 0;
  }

  .box-button-columns > a:last-child {
    margin-right: 0;
  }
}

/* box-button styles - end */

/* button-box styles - start */

@media only screen and (min-width: 1216px) {
  .button-box-columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 85%;
  }

  .button-box-columns .button-box {
    width: calc(90% - 35px);
  }

  .button-box-columns .button-box:first-child {
    /* margin-left: 0; */
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .button-box-columns {
    margin-left: 50px;
  }

  .button-box-columns .button-box {
    width: calc(70% - -95px);
  }

  .home .button-box {
    margin-left: 70px;
  }

  .home p {
    margin: 0;
    color: #444;
    /* margin-left: 30px; */
  }
}

/* button-box styles - end */

/*
@media only screen and (min-width: 800px) {
  .columns {
    display: flex;
    flex-wrap: wrap;
  }

  .columns .box {
    width: calc(50% - 20px);
  }

  .columns .box2 {
    width: calc(50% - 20px);
  }

  .columns .box-home {
    width: calc(25% - 20px);
  }

  .columns .box:first-child {
    margin-left: 0;
  }

  .columns .box2:first-child {
    margin-left: 0;
  }

  .columns .box-home:first-child {
    margin-left: 0;
  }

  .main-home .columns .box:first-child {
    margin-left: 0;
  }

  .columns .box:last-child {
    margin-right: 0;
  }

  .columns .box2:last-child {
    margin-right: 0;
  }

  .columns .box-home:last-child {
    margin-right: 0;
  }

  .columns > a {
    width: calc(50% - 20px);
  }

  .columns > a:first-child {
    margin-left: 0;
  }

  .columns > a:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 800px) {
  .columns .box {
    margin-bottom: 25px;
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .columns .box2 {
    margin-bottom: 25px;
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .columns .box-home {
    margin-bottom: 25px;
    width: 100%;
    display: block;
  }
}

/* comm columns -end */

/* home persona box image style - start */

.home .columns .box li img.inline {
  width: 20px;
  float: left;
  margin-right: 3.5px;
}

/* home persona box image style - end */

/* ss360 css - start */

.ss360-custom-search .ss360-custom-search__searchbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background-position: 4px 10px;
  /* background-repeat: no-repeat;
  /* background-size: 24px 24px; */
  background: #fff url(https://d33wubrfki0l68.cloudfront.net/18fc58b185ba9ad7f7131fa6810a67e7fd8e78a7/03f57/_/img/search.svg) !important;
  background-size: 1rem !important;
  background-position: right 0.8rem center !important;
  background-repeat: no-repeat !important;
  border: 1px solid #dedede;
  border-radius: 4px !important;
  /* box-shadow: 2px 2px 4px rgb(0 0 0 / 12%); */
  box-sizing: border-box;
  font-size: inherit !important;
  height: 33px !important;
  /* margin: 0; */
  margin-bottom: 12px !important;
  /* background: #fff url(https://d33wubrfki0l68.cloudfront.net/18fc58b…/03f57/_/img/search.svg) !important; */
  max-width: 420px;
  padding: 0 8px;
  width: 100%;
}

.ss360-custom-search .ss360-custom-search__searchbutton {
  align-items: center;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  cursor: pointer;
  /* display: flex; */
  font-size: 1em;
  height: 33px !important;
  justify-content: center;
  margin-top: 0;
  padding: 0 16px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  display: none !important;
}

.ss360-suggests__image-wrap {
  display: none !important;
  line-height: 0;
  max-width: 33%;
  min-height: 100%;
  min-width: 250px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 250px;
}

.ss360-layer--overlay {
  background-color: #fefefe;
  box-sizing: content-box;
  height: 80%;
  left: calc(10% - 28px);
  max-width: 800px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 32px 24px 32px 32px;
  position: fixed;
  top: calc(10% - 30px);
  width: 80%;
  border-radius: 4px !important;
  z-index: 99999999999;
}

#ss360-layer .ss360-ac-c {
  color: #000 !important;
}

#ss360-layer .ss360-ac-c--hover:focus,
#ss360-layer .ss360-ac-c--hover:hover {
  color: #fff !important;
}

/* search results window button styles */
#ss360-layer .ss360-ac-bg {
  background-color: #4e55fd !important;
  border-radius: 4px !important;
}
/* search results window button styles */
#ss360-layer .ss360-ac-bg--focus:focus,
#ss360-layer .ss360-ac-bg--hover:focus,
#ss360-layer .ss360-ac-bg--hover:hover {
  background-color: #4e55fd !important;
  color: #fefefe;
  border-radius: 4px !important;
}
/* more results button border */
#ss360-layer .ss360-ac-b,
#ss360-layer .ss360-ac-b--focus:focus,
#ss360-layer .ss360-ac-b--hover:focus,
#ss360-layer .ss360-ac-b--hover:hover {
  border-color: #4e55fd !important;
}
/* search suggestions text styles */
.home .unibox__search-content {
  display: block;
  margin-bottom: 4px;
  text-align: left;
  color: #444 !important;
  font-size: 14px !important;
}
/* search suggestions image styles */
.unibox__img-container {
  align-items: center;
  box-sizing: border-box;
  /* display: flex; */
  justify-content: flex-start;
  min-width: 68px;
  padding: 4px 0 4px 8px;
  display: none !important;
}
/* search suggestions width styles */
.unibox__content-container {
  box-sizing: border-box;
  max-width: calc(100% - 5px) !important;
  padding: 12px 8px 16px 16px;
  width: 100%;
}
/* search suggestions text color */
.unibox__search-content--link {
  color: #4a4f62 !important;
  overflow: hidden;
  text-decoration: none;
  font-size: 14px !important;
}
/* search suggestions heading text style */
.unibox__suggest-heading {
  color: #4a4f62;
  font-size: 13px !important;
  margin: 8px;
  padding: 0;
  text-align: left;
}

/* width of search box */
.ss360-custom-search {
  justify-content: center;
  max-width: 100%;
  padding: 8px 0;
  position: relative;
  width: 250px !important;
}
/* remove outline around search input field */
.ss360-n-input {
  background: #fefefe;
  background-image: none;
  border: 0;
  border-radius: 0;
  outline: none !important;
}
/* search suggestion drop-down styling */
.unibox {
  background-color: #fefefe;
  /* box-shadow: 1px 1px 6px rgb(0 0 0 / 12%); */
  -webkit-box-shadow: 0 28px 45px rgb(0 0 0 / 20%);
  color: #4a4f62;
  display: none;
  overflow: hidden;
  position: absolute;
  z-index: 1500;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

/* ss360 css - end */
