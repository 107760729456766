.toolbar {
  color: var(--toolbar-font-color);
  align-items: center;
  background-color: var(--toolbar-background);
  box-shadow: 0 1px 0 0 #e1e1e1;
  display: flex;
  font-size: calc(15 / var(--rem-base) * 1rem);
  height: var(--toolbar-height);
  justify-content: flex-start;
  position: sticky;
  top: var(--navbar-height);
  z-index: var(--z-index-toolbar);
}

.toolbar a {
  color: inherit;
}

.nav-toggle {
  background: url(../img/menu.svg) no-repeat 50% 47.5%;
  background-size: 49%;
  border: none;
  outline: none;
  line-height: inherit;
  padding: 0;
  height: var(--toolbar-height);
  width: var(--toolbar-height);
  margin-right: -0.25rem;
}

@media screen and (min-width: 1024px) {
  .nav-toggle {
    display: none;
  }
}

.nav-toggle.is-active {
  background-image: url(../img/back.svg);
  background-size: 41.5%;
}

a.badge.badge-dep-toc {
  display: inline-block;
  background-color: #ff595a;
  color: #fff;
  font-size: 9px;
  margin-left: 1px;
  margin-top: -1.01px;
  position: relative;
  top: 2px;
  border-radius: 9px;
  font-weight: 800;
  vertical-align: top;
  padding-top: 4px;
}

.home-link {
  display: block;
  background: url(../img/home-o.svg) no-repeat center;
  height: calc(var(--toolbar-height) / 2);
  width: calc(var(--toolbar-height) / 2);
  margin: calc(var(--toolbar-height) / 4);
}

.home-link:hover,
.home-link.is-current {
  background-image: url(../img/home.svg);
}

.edit-this-page {
  display: none;
  padding-right: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .edit-this-page {
    display: block;
  }
}

.toolbar .edit-this-page a {
  color: var(--toolbar-muted-color);
}

.search-look {
  display: none;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  /* flex: 1 1; */
  padding: 0 0.5rem 0 0.75rem;
  /* line-height: 1.35; */
  font-size: 14px;
  font-weight: 700;
  line-height: var(--nav-line-height);
}

@media screen and (min-width: 1024px) {
  .search-look {
    display: block;
  }
}

img.inline {
  width: 20px;
  float: none;
  margin-top: -8px;
  margin-bottom: 0;
  margin-right: 3.5px;
}
