*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: var(--body-font-size);
  height: 100%;
}

@media screen and (min-width: 1024px) {
  html {
    font-size: var(--body-font-size--desktop);
  }
}

body {
  background: var(--body-background);
  color: var(--body-font-color);
  font-family: var(--body-font-family);
  line-height: var(--body-line-height);
  margin: 0;
  word-wrap: anywhere; /* aka overflow-wrap; used when hyphens are disabled or aren't sufficient */
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  background-color: none;
}

code,
kbd,
pre {
  font-family: var(--monospace-font-family);
}

b,
dt,
strong,
th {
  font-weight: var(--body-font-weight-bold);
}

em em { /* stylelint-disable-line */
  font-style: normal;
}

strong strong { /* stylelint-disable-line */
  font-weight: normal;
}

button {
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  line-height: var(--body-line-height);
  margin: 0;
}

button::-moz-focus-inner {
  border: none;
  padding: 0;
}

table {
  border-collapse: collapse;
  word-wrap: normal; /* table widths aren't computed as expected when word-wrap is enabled */
}

@supports (scrollbar-width: thin) {
  body * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) transparent;
  }
}

body ::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.15rem;
}

body ::-webkit-scrollbar-thumb {
  /* background-color: var(--scrollbar-thumb-color); */
  background-color: #2222;
}
/* added from centered default ui */
.container {
  margin: 0 auto;
  max-width: var(--width-container);
  padding: 0 var(--width-container-gutter);
  width: 100%;
}
